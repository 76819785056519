@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;621&display=swap');

:root {
  --pink: rgb(146, 0, 213);
  --blue: rgb(143, 243, 235);
}

body {
  margin: 0;
  font-family:  Helvetica, "Futura", "The Antiqua B", Georgia, Droid-serif, serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #b9fffe;
  overflow: scroll;
  line-height: 17pt;
}

li {
  padding-bottom: 10px;
}

@media screen and (max-width: 900px) {
  body {
    font-size: 12pt;
  }
}

@media screen and (min-width: 901px) {
  body {
    font-size: 13pt;
  }
}

textarea:focus {
  border-color:var(--blue);
  border-width: 2px;
}
textarea {
  border-color: gray;
  border-width: 1px;
}
textarea,
textarea:focus {
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border-radius: 5px;
}

MuiSlider-valueLabel {

  background-color: #660c737d;
}

.big-font {
  font-family: 'Cinzel';
}

ul {
  list-style-type: ' ★ ';
}

scode {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .shopSlider { */
/* width: "20%; */
/* background-color: red; */
/* } */

.shopLabel {
  font-family: 'Cinzel';
}

@media screen and (max-width: 900px) {
  .shopSliderAndLabel {
    display: flex;
    flex-direction: column;
    /* padding: 50; */
    padding: 20px;
    width: 80%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .shopLabel {
    text-align: left;
    white-space: nowrap;
    text-align: right;
    margin-bottom: 30px;
  }

  .shopSlider {
    width: 100%;
    /* width: 20%; */
    /* width: 300; */
    /* background-color: red; */
  }

  .footerLink {
    height: 48px;
  }
}

ul {
  padding: 0;
  margin: 0;
}

a:hover,
a {
  color: var(--blue);
  text-decoration: none;
}

@media screen and (min-width: 901px) {
  .shopSliderAndLabel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    max-width: 90vw;
    width: 800px;
  }

  .shopLabel {
    text-align: right;
    min-width: 300px;
    padding-right: 20px;
    /* margin-right: 40; */
    white-space: nowrap;
    text-align: right;
  }


}
